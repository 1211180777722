import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import HeroSection from '../Components/HeroSection';
import VideoSection from '../Components/VideoSection';
import ServicesSection from '../Components/ServicesSection';
import ClientsSection from '../Components/ClientsSection';
import WorkSlideShow from '../Components/WorkSlideshow'
import WorkPage from './Work';
import ContactPage from './Contact';

export default function Home() {
  return (
    <div className="min-h-screen bg-white text-black">
      <Header />
      <main className='mt-0'>
        <section id="hero">
          <HeroSection />
        </section>
        <section id="video">
          <VideoSection />
        </section>
        <section id="about">
          <ServicesSection />
        </section>
        <section id="work">
          <WorkPage/>
        </section>
        {/* <section id="clients">
          <ClientsSection />
        </section> */}
        <section id="contact">
          <ContactPage/>
        </section>
      </main>
      <Footer />
    </div>
  );
}