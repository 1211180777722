// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

// Debug logging
console.log('Firebase Config:', {
  apiKey: process.env.REACT_APP_GOOGLE_API_KEY ? '✓' : '✗',
  authDomain: process.env.REACT_APP_AUTH_DOMAIN ? '✓' : '✗',
  projectId: process.env.REACT_APP_PROJECT_ID ? '✓' : '✗',
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET ? '✓' : '✗',
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID ? '✓' : '✗',
  appId: process.env.REACT_APP_APP_ID ? '✓' : '✗'
});

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
export const db = getFirestore(app);

// Initialize Storage
export const storage = getStorage(app);
